<template>
  <div @click.stop="play()" class="player">
    {{title}}
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    cont: String
  },
  data() {
    return {

    }
  },
  methods: {
    play() {
      fetch('/media.php?cont=' + this.cont)
        .then(r => r.json())
        .then(media => {
          //console.log(media)
          const info = new chrome.cast.media.MediaInfo(media.url)
          const request = new chrome.cast.media.LoadRequest(info)
          cast.framework.CastContext.getInstance().getCurrentSession().loadMedia(request)
        })
    }
  }
}
</script>

<style>
.player {
  cursor: pointer;
  border: 1px solid #dedede;
  padding: 1rem;
  margin-bottom: 0.2rem;
}
</style>