<template>
  <div>
    <div class="button-container" ref="buttonContainer"></div>
    <book-list />
  </div>
</template>

<script>

import BookList from './components/BookList'

export default {
  components: {
    BookList
  },
  mounted() {    
    window['__onGCastApiAvailable'] = (isAvailable) => {
      console.log('lol', isAvailable)
      if (isAvailable) {
        cast.framework.CastContext.getInstance().setOptions({
          receiverApplicationId:
            chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
        });
      }
    };
    const script = document.createElement('script');
    script.src = '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';

    const container = this.$refs.buttonContainer;
    const button = document.createElement('google-cast-launcher')
    
    container.appendChild(button)

    document.body.appendChild(script)
  }
}
</script>

<style>
body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.button-container {
  width: 2rem;
  height: 2rem;
  display: block;
}

</style>