<template>
  <div class="book" @click="toggle">
    <div class="title">{{title}}</div>
    <div class="author" v-if="author">
      {{author}}
    </div>
    <div v-if="episodes" class="episodes">
      <episode v-for="episode of episodes" v-bind="episode" :key="episode.cont" />
    </div>
  </div>
</template>

<script>

import Episode from "./Episode";

export default {
  components: {
    Episode,
  },
  props: {
    title: String,
    url: String,
    author: String
  },
  data() {
    return {
      showEpisodes: false,
      episodes: null,
    }
  },
  methods: {
    toggle() {
      if(this.episodes) {
        this.episodes = null
      } else {
        fetch(`/episodes.php?url=${encodeURIComponent(this.url)}`)
          .then(r => r.json())
          .then(episodes => {
            this.episodes = episodes
          })
      }
      
    }
  }
}
</script>

<style>
.book {
  padding: 1rem;
  border: 1px solid #cdcdcd;
  margin-bottom: 0.5rem;
}
.title {
  font-weight: bold;
}
.reader {
  font-size: 0.9em;
}
</style>