<template>
  <div class="book-list">
    <book v-for="book of list" :key="book.url" v-bind="book" />
  </div>
</template>

<script>
import Book from "./Book";

export default {
  components: {
    Book
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    fetch('/urls.php')
      .then(r => r.json())
      .then(list => {
        this.list = Object.entries(list).map(([url, {title, author}]) => {
          let reader
          return {
            url, 
            title,
            author
          }
        })
      })
  }
}
</script>

<style>

</style>